<template>
  <search
    selected-document-type='accounts'
    :included-document-types="documentTypes"
  />
</template>

<script>
export default {
  name: 'home',
  components: {
    Search: () => import('@/pages/mobility/components/search')
  },
  activated () {
    this.setModule()
  },
  computed: {
    documentTypes () { return ['accounts'] }
  },
  created () {
    this.setModule()
  },
  methods: {
    setModule () {
      this.$store.commit('setModule', { name: this.$t('t.Hello', { name: this.$store.getters.currentUser.fullname }) })
    }
  }
}
</script>
